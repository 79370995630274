import React from 'react';
import _ from 'lodash';
import styles from './CommentInput.scss';
import TextArea from '../FullWidthTextArea';
import { TextButton, TEXT_BUTTON_PRIORITY } from '../TextButton';
import Text from '../../core-components/Text';
import classNames from 'classnames';

export interface CommentInputProps {
  value: string;
  forceInputVisibility?: boolean;
  onChange: (value: string) => void;
  content: string;
  dataHook?: string;
  buttonClassName?: string;
  textClassName?: string;
  placeholder?: string;
  disabled?: boolean;
  onOpen?: () => void;
  onBlur?: () => void;
  fullWidthButton?: boolean;
}

const MaxLength = 240;

const CommentInput: React.FC<CommentInputProps> = ({
  value,
  forceInputVisibility,
  onChange,
  content,
  dataHook,
  buttonClassName,
  textClassName,
  placeholder,
  disabled,
  onOpen,
  onBlur,
  fullWidthButton,
}) => {
  const [isOpen, setIsOpen] = React.useState(Boolean(value) || forceInputVisibility);
  const [shouldUseAutoFocus, setShouldUseAutoFocus] = React.useState(false);
  const [stateValue, setStateValue] = React.useState(value); // prevent cursor bugs

  const handleBlur = React.useCallback(() => {
    if (stateValue) {
      const trimmed = _.trim(stateValue);
      setStateValue(trimmed);
      onChange(trimmed);
    } else {
      onChange('');
      setIsOpen(false);
    }
    setShouldUseAutoFocus(false);
    onBlur && onBlur();
  }, [stateValue, onChange, onBlur]);

  const handleClickTextButton = () => {
    setIsOpen(true);
    setShouldUseAutoFocus(true);

    if (onOpen) {
      onOpen();
    }
  };

  React.useEffect(() => {
    if (value) {
      setIsOpen(true);
    }
    setStateValue(value);
  }, [value]);

  const textButtonClassName = classNames(buttonClassName, { [styles.fullWidthButton]: fullWidthButton });

  return (
    <>
      {!isOpen && (
        <TextButton
          priority={TEXT_BUTTON_PRIORITY.primary}
          onClick={handleClickTextButton}
          disabled={disabled}
          data-hook={`${dataHook}-button`}
          className={textButtonClassName}
        >
          <Text typography="p2-m-colorless">{content}</Text>
        </TextButton>
      )}
      {isOpen && (
        <div className={classNames(styles.innerWrapper, textClassName)}>
          <Text typography="p2-s" className={styles.title}>
            {content}
          </Text>
          <TextArea
            data-hook={`${dataHook}-text-area`}
            placeholder={placeholder || content}
            ariaLabel={content}
            value={stateValue}
            onChange={(e) => {
              const text = e.target.value.substr(0, MaxLength);
              setStateValue(text);
            }}
            onBlur={handleBlur}
            autoFocus={shouldUseAutoFocus}
            withFocusRing
          />
        </div>
      )}
    </>
  );
};

CommentInput.displayName = 'CommentInput';

export default CommentInput;
